
<template>
  <div class="schedu-body  common-pop">
    <el-dialog  :title="'【调度】订单任务'" :close-on-click-modal="false" width="70%" :visible.sync="visible">
      <div class="time">
        提货时间：{{dataForm.takeGoodsDate}}
      </div>
      <el-form :model="dataForm" ref="dataForm" label-width="40px"  class="schedu">
        <el-row :gutter="50" style="margin-top: 10px;">
          <el-col :span="24">
            <el-steps :active="1" :align-center="true" >
              <el-step title="车队" :status="dataForm.motorcadeId ? 'finish' : 'process'"></el-step>
              <el-step title="车型" :status="dataForm.vehicleTypeId ? 'finish' : 'wait'"></el-step>
              <el-step title="车辆" :status="dataForm.vehicleId ? 'finish' : 'wait'"></el-step>
            </el-steps>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="7">
            <div class="box">
                <span class="search">
                    <el-input placeholder="输入车队名称" v-model="motorcadeText" prefix-icon="el-icon-search" clearable>
                    </el-input>
                </span>
              <div class="carModelList">
                <el-tree
                  class="filter-tree"
                  :data="companyDataList"
                  node-key="id"
                  :props="defaultProps"
                  :highlight-current="true"
                  :accordion="true"
                  :current-node-key="dataForm.motorcadeId"
                  :filter-node-method="filterMotorcadeNode"
                  @node-click="nodeClick"
                  @node-expand="nodeExpand"
                  ref="motorcadeTrue"
                  :default-expand-all="true">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                  </span>
                </el-tree>
              </div>
               <span class="staCount">
                  车队数量 <strong> {{ motorcadeNum || 0 }} </strong>
              </span>
            </div>
          </el-col>
          <el-col :span="7">
              <div class="box">
                <span class="search">
                  <el-input type="text" placeholder="请输入车型名称" :disabled="vehicleTypeList.length === 0" v-model="vehicleTypeText" prefix-icon="el-icon-search" ></el-input>
                </span>
                <div class="carModelList" v-if="vehicleTypeList.length">
                  <div style="width:50%" @click="selectCarType(item, index)"
                       :class="isModelActive == index ? 'modelActive' : ''"
                       class="first div-col-12" :key="item.id" v-for="(item, index) in vehicleTypeList">
                    <span class="model">{{item.name}}</span>
                    <span class="mark">{{item.vehicleNum}}</span>
                  </div>
                </div>
                <div class="tipWord"  v-else>
                    暂无数据
                </div>
                <span class="staCount">
                    车型数量 <strong> {{vehicleTypeList.length }} </strong>
                </span>
              </div>
          </el-col>
           <el-col :span="10">
            <div class="box">
              <span class="search">
                <el-input type="text" placeholder="输入车牌号" :disabled="vehicleInfoList.length === 0" v-model="vehicleText" prefix-icon="el-icon-search"></el-input>
              </span>
              <div class="carList" v-if="dataForm.vehicleTypeId">
                <el-row>
                  <div style="width:50%" @click="addPoC()" class="first div-col-12 addPoC">
                      <span class="model"> + 快速新增人车 </span>
                  </div>
                  <div style="width:50%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" @click ="selectCarInfo(item, index)"  :class = "isactive == index ? 'modelActive' : '' " class="first div-col-12" :key="item.id" v-for="(item, index) in vehicleInfoList">
                    <!-- <el-col :span="12" class="first"  v-for="item in vehicleInfoList" :key="item.id"> -->
                      <span class="model">{{ item.vehiclePlate }} <span style="color:blue">{{ item.driverName }}</span>
                        <img src="@/../static/img/start.png" width="20"><span style="font-size: 12px">{{item.currentCity}}</span>
                      </span>
                    <span class="mark" @click="queryOrderAddressList(item.driverId,item.orderNum)">
                      {{ item.orderNum }}
                    </span>
                    <!-- </el-col> -->
                  </div>
                </el-row>
            </div>
            <div class="tipWord"  v-else>
              暂无数据
            </div>
            <span class="staCount">
                车辆 <strong> {{ vehicleInfoList.length }}</strong> 辆
            </span>
          </div>
        </el-col>
        </el-row>
        <el-row :gutter="50" style="margin-top: 30px">
          <el-col :span="24">
            <el-form-item label="备注" >
              <el-input type="textarea" :rows="2" v-model="dataForm.remark" maxlength="500" placeholder="如需特殊交代驾驶员，请在此输入……（限100字）"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="todo == 'reassign'">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()">重新指派</el-button>
      </span>
      <span slot="footer" class="dialog-footer"  v-else-if="todo != 'wayReassign'">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit(1)">提交计划</el-button>
        <el-button type="primary" @click="dataFormSubmit(2)">提交且发布</el-button>
      </span>
    </el-dialog>
    <fast-add-car ref="fastAddCar" @refreshDispatch="refreshDispatch"></fast-add-car>
  </div>
</template>

<script>
import fastAddCar from './fastAddCar'
export default {
  components: {
    fastAddCar
  },
  data () {
    return {
      visible: false,
      isactive: -1,
      isModelActive: -1,
      companyDataList: [],
      vehicleInfoList: [],
      vehicleTypeList: [],
      motorcadeNum: 0,
      motorcadeText: null,
      vehicleTypeText: null,
      vehicleText: null,
      row: {},
      todo: '',
      dataForm: {
        id: null,
        oldPlanId: null,
        planType: null,
        takeGoodsDate: null,
        orderInfoList: [],
        motorcadeId: null,
        motorcadeName: null,
        driverId: null,
        vehicleId: null,
        vehiclePlate: null,
        vehicleTypeId: null,
        vehicleTypeName: null,
        remark: null
      },
      defaultProps: {
        children: 'motorcadeInfoList',
        label: 'simpleName'
      }
    }
  },
  watch: {
    vehicleInfoList (val) {
      val.forEach((item, index) => {
        if (item.id === this.dataForm.vehicleId) {
          this.isactive = index
        }
      })
    },
    vehicleTypeList (val) {
      val.forEach((item, index) => {
        if (item.id === this.dataForm.vehicleTypeId) {
          this.isModelActive = index
        }
      })
    },
    motorcadeText (text) {
      this.$refs.motorcadeTrue.filter(text)
    },
    vehicleTypeText (text) {
      if (text !== null) {
        this.findVehicleTypeList(text)
      }
    },
    vehicleText (text) {
      this.findVehicleList(text)
    }
  },
  methods: {
    addPoC () {
      this.$refs.fastAddCar.init({
        motorcadeId: this.dataForm.motorcadeId,
        vehicleTypeId: this.dataForm.vehicleTypeId
      })
    },
    refreshDispatch (data) {
      this.findVehicleTypeList()
      this.findVehicleList()
    },
    // 选择车辆信息
    selectCarInfo (row, index) {
      if (!row.driverId) {
        this.$message.error('车辆未绑定驾驶员，请先绑定！')
        return
      }
      if (row.driverStatus === 2) {
        this.$message.error('车辆未绑定驾驶员，请绑定')
        return
      }
      if (row.driverStatus === 3) {
        this.$message.error('车辆绑定的驾驶员已被禁用，请重新绑定')
        return
      }
      if (row.driverStatus === 4) {
        this.$message.error('车辆绑定的驾驶员已离职，请重新绑定')
        return
      }
      this.isactive = index
      this.dataForm.vehicleId = row.id
      this.dataForm.driverId = row.driverId
      this.dataForm.vehiclePlate = row.vehiclePlate
      if (this.todo === 'wayReassign') {
        this.$emit('reassignSubmit', this.dataForm)
      }
    },
    // 选择车型
    selectCarType (row, index) {
      this.isModelActive = index
      this.dataForm.vehicleTypeId = row.id
      this.dataForm.vehicleTypeName = row.name
      this.isactive = -1
      this.findVehicleList()
    },
    showModule () {
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
    },
    clearData () {
      this.companyDataList = []
      this.vehicleInfoList = []
      this.vehicleTypeList = []
      this.motorcadeText = null
      this.vehicleTypeText = null
      this.vehicleText = null
      this.motorcadeNum = 0
      this.dataForm.id = null
      this.dataForm.motorcadeId = null
      this.dataForm.motorcadeName = null
      this.dataForm.vehiclePlate = null
      this.dataForm.vehicleTypeId = null
      this.dataForm.vehicleTypeName = null
      this.dataForm.planType = null
      this.dataForm.takeGoodsDate = null
      this.dataForm.orderInfoList = []
      this.dataForm.driverId = null
      this.dataForm.vehicleId = null
      this.dataForm.remark = null
    },
    // 节点点击查询车型
    nodeClick (data, node, component) {
      // if (data.parent || this.dataForm.motorcadeId === data.id) {
      //   return
      // }
      // console.log(data)
      this.dataForm.motorcadeId = data.id
      this.dataForm.motorcadeName = data.simpleName
      this.dataForm.vehiclePlate = null
      this.dataForm.vehicleTypeId = null
      this.dataForm.vehicleTypeName = null
      this.dataForm.driverId = null
      this.dataForm.vehicleId = null
      this.isactive = -1
      this.isModelActive = -1

      // 清空车辆
      this.vehicleInfoList = []
      this.vehicleTypeList = []
      this.findVehicleTypeList()
    },
    // 父节点展开设置选中节点
    nodeExpand () {
      this.$refs.motorcadeTrue.setCurrentKey(this.dataForm.motorcadeId)
    },
    filterMotorcadeNode (value, data, node) {
      if (!value) return true
      return data.simpleName.indexOf(value) !== -1
    },
    close () {
      this.visible = false
    },
    init (row, todo) {
      this.clearData()
      this.dataForm.orderInfoList = []
      this.dataForm.takeGoodsDate = null
      this.dataForm.planType = null
      this.row = row
      this.todo = todo
      this.isactive = -1
      this.isModelActive = -1
      // 车次 重新指派
      if (todo === 'reassign') {
        this.dataForm.takeGoodsDate = row.takeGoodsDate
        this.dataForm.motorcadeId = row.motorcadeId
        this.dataForm.motorcadeName = row.motorcadeName
        this.dataForm.vehiclePlate = row.vehiclePlate
        this.dataForm.vehicleTypeId = row.vehicleId
        this.dataForm.vehicleTypeName = row.vehicleTypeName
        this.dataForm.planType = row.planType
        this.dataForm.takeGoodsDate = row.takeGoodsDate
        this.dataForm.driverId = row.driverId
        this.dataForm.vehicleId = row.vehicleId
        this.dataForm.remark = row.remark
        this.dataForm.id = row.id
        // 选中车队
        this.nodeClick({
          id: row.motorcadeId,
          simpleName: row.motorcadeName
        })
        let self = this
        // 选中车型
        self.selectCarType({
          id: row.vehicleTypeId,
          name: row.vehicleTypeName
        })
        // 选中车
        self.selectCarInfo({
          driverId: row.driverId,
          id: row.vehicleId,
          vehiclePlate: row.vehiclePlate
        })
      } else if (todo === 'wayReassign') {
        // 运单重新指派
        this.dataForm.orderInfoList.push({
          id: row.orderInfo.id
        })
        this.dataForm.takeGoodsDate = row.orderInfo.takeGoodsDate
        this.dataForm.planType = row.orderInfo.orderType
        this.dataForm.oldPlanId = row.vehiclePlan.id
      } else {
        row.forEach(item => {
          this.dataForm.orderInfoList.push({
            id: item.orderInfo.id
          })
          this.dataForm.takeGoodsDate = item.orderInfo.takeGoodsDate
          this.dataForm.planType = item.orderInfo.orderType
        })
      }
      // 车队信息
      this.$http({
        url: this.$http.adornUrl('/company/findCompanyInMotorcade'),
        method: 'get'
      }).then(({ data }) => {
        if (data.length === 0) {
          this.$message.error('请维护公司车队信息！')
        } else {
          this.companyDataList = data
          for (let i = 0; i < data.length; i++) {
            this.companyDataList[i].parent = true
            this.motorcadeNum += data[i].motorcadeInfoList.length
          }
        }
      }).then(() => {
        this.showModule()
      }).then(() => {
      })
    },
    dataFormSubmit (type) {
      if (!this.dataForm.motorcadeId) {
        this.$message.error('请选择车队！')
        return
      }
      if (!this.dataForm.vehicleTypeId) {
        this.$message.error('请选择车型！')
        return
      }
      if (!this.dataForm.vehicleId) {
        this.$message.error('请选择车！')
        return
      }
      // 过滤全为专车的
      if (this.todo !== 'reassign') {
        let rows = this.row.filter(item => {
          return item.orderInfo.orderType === 1
        })
        // 全为专车时
        if (rows.length === this.row.length) {
          // 重新指派时
          if (this.todo === 'reassign') {
            if (this.row.orderInfo.vehicleType !== this.dataForm.vehicleTypeId) {
              this.$confirm('选择的车型与订单车型不相同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.submit(type)
              }).catch(() => {
              })
            }
          } else {
            let matchLen = 0
            this.row.forEach(item => {
              // 全部为专车 && 运单车型与选中车型一致
              if (item.orderInfo.orderType && (item.orderInfo.vehicleType === this.dataForm.vehicleTypeId)) {
                matchLen++
              }
            })
            if (matchLen !== this.row.length) {
              this.$confirm('选择的车型与订单车型不相同, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.submit(type)
              }).catch(() => {
              })
            } else {
              this.submit(type)
            }
          }
        } else {
          this.submit(type)
        }
      } else {
        if (this.dataForm.vehicleId === this.row.vehicleId) {
          this.$message.error('选择的是同一辆车，请检查后再试')
        } else {
          this.reassignSubmit()
        }
      }
    },
    reassignSubmit () {
      this.$http({
        url: this.$http.adornUrl(`/vehiclePlan/planReassign`),
        method: 'post',
        data: this.$http.adornData({
          motorcadeId: this.dataForm.motorcadeId,
          motorcadeName: this.dataForm.motorcadeName,
          vehicleId: this.dataForm.vehicleId,
          id: this.dataForm.id,
          vehiclePlate: this.dataForm.vehiclePlate,
          vehicleTypeId: this.dataForm.vehicleTypeId,
          vehicleTypeName: this.dataForm.vehicleTypeName,
          driverId: this.dataForm.driverId,
          remark: this.dataForm.remark,
          orderInfoList: this.dataForm.orderInfoList
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '重新指派成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }
      })
    },
    submit (type) {
      this.$http({
        url: this.$http.adornUrl(`/vehiclePlan/createPlan`),
        method: 'PUT',
        data: this.$http.adornData({
          planType: this.dataForm.planType,
          takeGoodsDate: this.dataForm.takeGoodsDate,
          motorcadeId: this.dataForm.motorcadeId,
          motorcadeName: this.dataForm.motorcadeName,
          vehicleId: this.dataForm.vehicleId,
          vehiclePlate: this.dataForm.vehiclePlate,
          vehicleTypeId: this.dataForm.vehicleTypeId,
          vehicleTypeName: this.dataForm.vehicleTypeName,
          driverId: this.dataForm.driverId,
          oldPlanId: this.dataForm.oldPlanId,
          remark: this.dataForm.remark,
          status: type,
          orderInfoList: this.dataForm.orderInfoList
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '调度成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }
      })
    },
    // 车型信息
    findVehicleTypeList (text) {
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findByMotorcadeId`),
        method: 'GET',
        params: this.$http.adornParams({
          'motorcadeId': this.dataForm.motorcadeId,
          'name': text
        })
      }).then(({ data }) => {
        if (data.length === 0) {
          this.$message.warning('车队下没有车辆车型')
        }
        this.vehicleTypeList = data
      })
    },
    findVehicleList (text) {
      this.$http({
        url: this.$http.adornUrl(`/vehicleInfo/findByMotorcadeId`),
        method: 'GET',
        params: this.$http.adornParams({
          'motorcadeId': this.dataForm.motorcadeId,
          'vehicleType': this.dataForm.vehicleTypeId,
          'takeDate': this.dataForm.takeGoodsDate,
          'vehiclePlate': text
        })
      }).then(({ data }) => {
        // if (data.length === 0) {
        //   this.$message.warning('该车型没有车！')
        // }
        this.vehicleInfoList = data
      })
    },
    queryOrderAddressList (driverId, orderNum) {
      if (orderNum <= 0) {
        return
      }
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/findAddressByDriverId'),
        method: 'GET',
        params: this.$http.adornParams({
          'motorcadeId': this.dataForm.motorcadeId,
          'vehicleType': this.dataForm.vehicleTypeId,
          'takeDate': this.dataForm.takeGoodsDate,
          'driverId': driverId
        })
      }).then(({ data }) => {
        if (data.length > 0) {
          // eslint-disable-next-line no-unused-vars
          var str = ''
          for (let i = 0; i < data.length; i++) {
            str += (1 + i) + '.' + data[i] + '<br/>'
          }
        }
        // 直接弹出提示订单列表
        this.$alert(str, '订单列表', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
  .row-bottom {
    border: 1px solid #e4e7ed;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #e4e4e4;
  }
  .modelActive{
      background: #f0f7ff;
  }
  .el-dialog {
    margin-top: 3vh !important;
  }
  .red-text {
    color: red;
    float: right;
    border-radius: 12px;
    border: 1px solid red;
    width: 22px;
    margin-right: -15px;
    height: 24px;
    margin-top: -5px;
    text-align: center;
    line-height: 22px;
  }
  .div-height {
    border: 1px solid #e4e7ed;
    height: 100%;
    overflow-y: auto;
  }
.schedu-body {
  .el-dialog__body {
    padding: 10px !important
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-row {
    // margin-top: 10px;
  }
  .addPoC {
    color: #45c2b5;
    text-align: center;
    // font-weight: 500;
  }
  .time {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 50px;
  }
  .schedu {
    .title {
      display: block;
      height: 38px;
      line-height: 38px;
    }
    .box {
        border: 1px solid #ccc;
        height: 350px;
        position: relative;
        input.el-input__inner {
          border: 0;
          border-bottom: 1px solid #ccc;
          border-radius: 0;
        }
        .carModelList,.carList {
          height: 280px;
          overflow: auto;
          .el-col.el-col-12,.el-col.el-col-8, .div-col-12 {
            display: inline-block;
            text-align: left;
            padding-left: 10px;
            height: 36px;
            line-height: 36px;
            position: relative;
            max-height: 240px;
            span.model {
              display: inline-block;
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
            .activeModel {
              background: #ccc
            }
            span.mark {
              text-align: center;
              border: 1px solid red;
              color: red;
              border-radius: 50%;
              padding: 2px;
              box-sizing: border-box;
              position: absolute;
              right: 0px;
              height: 30px;
              width: 30px;
              line-height: 27px;
              top: 3px;
              cursor: pointer;
            }
            .el-row {
              margin-top: 0;
            }
          }
          .first {
              border-right: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
          }
          .other {
            background: #eee;
            text-align: center
          }
          .second {
              border-bottom: 1px solid #ccc;
          }
          .third {
              border-left: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
          }
        }
        .tipWord {
          text-align: center;
          margin-top: 50px;
          color: #999;
          font-size: 20px;
        }
      .staCount {
        position: absolute;
        bottom: 0;
        display: block;
        background: #eee;
        border-top: 1px solid #ccc;
        width: 100%;
        height: 32px;
        line-height: 32px;
        text-align: center
      }
    }
  }
}
</style>
